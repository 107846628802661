const mantineTheme = {
  fontFamily: `"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
  colorScheme: 'dark',
  colors: {
    primary: '#7B39B6',
    secondary: [
      '#D9F4FF',
      '#A7E6FF',
      '#7BD9FF',
      '#52CDFF',
      '#2DC3FF',
      '#21B3FF',
      '#21A2EC',
      '#009CFC',
      '#0093F8',
      '#0084E0',
      '#0077C9',
      '#006BB5',
      '#0060A3'
    ]
  },
  primaryColor: 'primary',
  secondaryColor: 'secondary',
  breakpoints: {
    xs: '30em', // 480px
    sm: '48em', // 768px
    md: '62em', // 992px
    lg: '75em', // 1200px
    xl: '90em' // 1440px
  }
}

export default mantineTheme
