import { useLocalStorage } from '@mantine/hooks'
import PropTypes from 'prop-types'
import { createContext, useContext, useEffect } from 'react'

const LocaleContext = createContext(null)

const LocaleContextProvider = ({ children }) => {
  const [country, setCountry] = useLocalStorage({
    key: 'country',
    getInitialValueInEffect: false
  })
  /*   const [language, setLanguage] = useLocalStorage({
    key: 'language',
    getInitialValueInEffect: false
  }) */

  const [platforms, setPlatforms] = useLocalStorage({
    key: 'platform',
    getInitialValueInEffect: false
  })

  /*   useEffect(() => {
    if (!language) {
      setLanguage(window.navigator.language)
    }
  }, [language, setLanguage]); */

  useEffect(() => {
    if (!country) {
      fetch('/countries.json')
        .then((res) => res.json())
        .then((res) => {
          if (Intl) {
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const tzArr = userTimeZone.split('/')
            const userCity = tzArr[tzArr.length - 1]
            setCountry(
              res.find((c) => c.cities.includes(userCity)).code.toLowerCase()
            )
          }
        })
    }
  }, [country, setCountry])

  const value = {
    country,
    setCountry,
    /*     language,
    setLanguage, */
    platforms,
    setPlatforms
  }
  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
}

LocaleContextProvider.propTypes = {
  children: PropTypes.node
}

const useLocaleContext = () => useContext(LocaleContext)

export { LocaleContext, LocaleContextProvider, useLocaleContext }
