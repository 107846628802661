import { queryTypes, useQueryState } from "next-usequerystate";
import PropTypes from "prop-types";
import { createContext, useContext, useState } from "react";

const AppContext = createContext(null);

const AppContextProvider = ({ children }) => {
  const [query, setQuery] = useState("");
  const [urlQuery, setUrlQuery] = useQueryState(
    "q",
    queryTypes.string.withDefault("")
  );
  const value = {
    query,
    setQuery,
    urlQuery,
    setUrlQuery,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AppContextProvider.propTypes = {
  children: PropTypes.node,
};

const useAppContext = () => useContext(AppContext);

export { AppContext, AppContextProvider, useAppContext };
