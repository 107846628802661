import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { Inter } from '@next/font/google'
import Cookies from 'js-cookie'
import { SessionProvider } from 'next-auth/react'
import { NextIntlClientProvider } from 'next-intl'
import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar'
import { useRouter } from 'next/router'
import Script from 'next/script' // Importamos Script de Next.js
import PropTypes from 'prop-types'
import ErrorReportForm from '../components/error_report'
import Insights from '../components/insights.js'
import CookieBanner from '../components/layout/cookie_banner.js'
import { AppContextProvider } from '../context/app'
import { LocaleContextProvider } from '../context/locale'
import '../styles/globals.css'
import mantineTheme from '../theme/mantineTheme'

const interFont = Inter({
  weight: ['400', '500', '700'],
  subsets: ['latin']
})

export default function App({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()

  const consentCookie = Cookies.get('cookie_consent')
  const consentObj = consentCookie ? JSON.parse(consentCookie) : null

  return (
    <SessionProvider session={pageProps.session}>
      <NextIntlClientProvider
        locale={router.locale}
        timeZone="Europe/Vienna"
        messages={pageProps.messages}
        onError={(error) => {
          if (error.code === 'MISSING_MESSAGE') {
            console.warn(`Missing translation: ${error.message}`)
            return error.message.defaultMessage
          }
        }}
      >
        {consentObj?.analysis ? (
          <Script
            id="gtm-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KZ8PNZVJ');
          `
            }}
          />
        ) : null}
        {consentObj?.analysis ? (
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=G-8HJZCG3ZWC`}
          />
        ) : null}
        {consentObj?.analysis ? (
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8HJZCG3ZWC');
          `
            }}
          />
        ) : null}

        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          className={interFont.className}
          theme={mantineTheme}
        >
          <Notifications />

          <LocaleContextProvider>
            <AppContextProvider>
              <CookieBanner />
              <ErrorReportForm />
              {getLayout(<Component {...pageProps} key={router.asPath} />)}
              <ProgressBar
                height="4px"
                color="#7B39B6"
                options={{ showSpinner: false }}
                shallowRouting
              />
              <Insights />
            </AppContextProvider>
          </LocaleContextProvider>
        </MantineProvider>
      </NextIntlClientProvider>
    </SessionProvider>
  )
}

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired
}
